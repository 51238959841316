<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>Add User</v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid2" lazy-validation>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="nameUsers"
                  :rules="nameRules2"
                  label="Name"
                  required
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-file-input
                  v-model="photo"
                  :rules="photoRules2"
                  label="Photo"
                  type="file"
                  required
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="email2"
                  :rules="emailRules2"
                  label="E-mail"
                  required
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="password"
                  :rules="passwordRules"
                  :type="'password'"
                  label="Password"
                  hint="At least 8 characters"
                  counter
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="konfpassword"
                  :rules="passwordRules"
                  :type="'password'"
                  label="Confirmation Password"
                  hint="At least 8 characters"
                  counter
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-select
                  v-model="group_name"
                  :items="['ADMIN', 'USER']"
                  :rules="[(v) => !!v || 'Group Name is required']"
                  label="Group Name"
                  required
                />
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="status"
                  :items="['ACTIVE', 'NON ACTIVE']"
                  :rules="[(v) => !!v || 'Status is required']"
                  label="Status"
                  required
                />
              </v-col>
            </v-row>

            <v-btn
              :disabled="!valid2"
              color="success"
              class="mr-4"
              @click="validate"
            >
              Save
            </v-btn>
          </v-form>
        </v-card-text>
      </base-card>
    </v-col>
  </v-row>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Add Users",
  },
  data() {
    return {
      // Validation with submit & clear
      valid2: true,
      nameUsers: "",
      photo: null,
      konfpassword: "",
      password: "",
      group_name: null,
      status: null,
      email2: "",
      nameRules2: [(v) => !!v || "Name is required"],
      photoRules2: [(v) => !!v || "Photo is required"],
      emailRules2: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      passwordRules: [
        (v) => !!v || "Password is required.",
        (v) => v.length >= 8 || "Min 8 characters",
      ],
    };
  },
  computed: {
    ...mapGetters(["GEMAS_BACKEND"]),
  },
  // Validation with submit & clear
  methods: {
    validate() {
      // this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        if (this.password == this.konfpassword) {
          let postData = new FormData();
          postData.append("nameUsers", this.nameUsers);
          postData.append("password", this.password);
          postData.append("group_name", this.group_name);
          postData.append("status", this.status);
          postData.append("email", this.email2);
          postData.append("photo", this.photo);
          axios
            .post(this.GEMAS_BACKEND + "account/addUsers", postData, {
              headers: { "Content-Type": "multipart/form-data" },
            })
            .then((res) => {
              this.listUsers = res.data.account;
              this.paginate_total = Math.ceil(
                res.data.total / res.data.limit_per_page
              );
              this.nameUsers = "";
              this.photo = null;
              this.konfpassword = "";
              this.password = "";
              this.group_name = null;
              this.status = null;
              this.email2 = "";
              console.log(this.listUsers);
            })
            .catch((err) => {
              // handle error
              console.log(err);
            });
        } else {
          //alert
        }
      }
    },
  },
};
</script>
